import React from "react";
import { Layout } from "../../components/common/layout";
import { Banner } from "../../components/common/banner";
import { Intent } from "../../components/common/intent";
import backgroundIMG from "../../components/assets/img/pages/solucoes/subpages/ferrovia-e-mineracao.jpg";

const FerroviaPage  = () => (
        <Layout infoPage={{page: "Ferrovia e Mineração"}}>
            <main className="solucoes-subpage">
                <Banner backgroundImage={backgroundIMG}>
                    <h1 className="title bold">FERROVIA E MINERAÇÃO</h1>
                </Banner>
                <section className="content">
                    <p className="text">Com uso de Sistemas Avançados e Tecnologia RFID aplicado em ativos e componentes vagões, é possível obter informações precisas e em tempo real de todos ativos e componentes vagões circulantes na ferrovia.</p>
                    <p className="text">Com a identificação de cada ativo em operação com Tags de RFID UHF Especiais, específicas para uso em ferrovia e para cada tipo de componente é possível obter informação sobre:</p>
                    <ul className="list">
                        <li className="text">Quantas viagens foram realizadas da mina até o virador.</li>
                        <li className="text">Quantos km foram percorridos por cada componente.</li>
                        <li className="text">Quanto tempo o componente está em operação desde a última manutenção.</li>
                        <li className="text">Percentual e Indicador de manutenção de cada ativo componente (status de saúde da operação ferrovia).</li>
                        <li className="text">Quantos e quais componentes devem ser mantenidos na próxima semana ou no próximo mês, desse modo, gerando informações para provisionamento de compra de novos componentes e insumos, bem como, provisionando tempo de alocação de mão de obra em oficinas para determinados componentes.</li>
                        <li className="text">Gestão total de manutenção de rolamentos e válvula de freios, itens que são críticos para operação em ferrovia, por questão de segurança.</li>
                        <li className="text">Gestão total de rodas e rodeiros, provisionando quais bitolas e diâmetros de rodas e rodeiros para manutenção preventiva.</li>
                        <li className="text">Tempo despendido em manutenção por vagão em oficinas.</li>
                        <li className="text">Percentual e indicador de eficiência da operação logística.</li>
                        <li className="text">Percentual e indicador de eficiência da operação oficina de manutenção.</li>
                        <li className="text">Identificação de modo preciso de qual rolamento de qual vagão deve ser substituído, integrado com o sistema de hotbox e com o sistema de RFID.</li>
                        <li className="text">Disponibilizamos também a posição geográfica online dos vagões e locomotivas, km percorrida por período, velocidades máximas se medias de vagões e locomotivas.</li>
                    </ul>
                    <Intent classes="button" to="/#solutions">
                        <span className="medium">DEMAIS SOLUÇÕES</span>
                    </Intent>
                </section>
            </main>
        </Layout>
    )

export default FerroviaPage ;